import React, { useEffect, useState } from "react";
import Bio from "./Bio";

function Home() {
  const [proyectos, setProyectos] = useState([]);
  const [textoBanner, setTextoBanner] = useState("");
  const [images, setImages] = useState([]);
  const [failedImages, setFailedImages] = useState({}); // Estado para almacenar imágenes que fallaron

  // Lista de imágenes disponibles en la carpeta public/img
  const imageList = [
    "/img/nada/la nada.jpg",
    "/img/nada/la_nada_2.jpg",
    "/img/otros/carriz.jpeg",
    "/img/revistas/expos.jpg",
    "/img/revistas/expos1.jpg",
    "/img/revistas/expos2.jpg",
    "/img/101/101.jpg",
    "/img/altu/alaltulalleva-1024x724.jpg",
    "/img/articulos/artículos_2.jpg",
    "/img/articulos/artículos.jpg",
    "/img/bio/img-4c5bea389218-1.jpeg",
    "/img/boca-abierta/bocaabierta1.jpg",
    "/img/boca-abierta/bocaabierta2.jpg",
    "/img/boca-abierta/bocaabierta3.jpg",
    "/img/boca-abierta/bocaabierta4.jpg",
    "/img/boca-abierta/bocaabierta5.png",
    "/img/arriba/arriba.png",
  ];

  useEffect(() => {
    // Cargar datos de data.json
    fetch("/data.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setProyectos(data.proyectos);
        setTextoBanner(data.bannerText); // Almacena el texto con HTML
      })
      .catch((error) => console.error("Error fetching data:", error));

    // Función para seleccionar una imagen aleatoria
    const getRandomImage = () => {
      const shuffled = imageList.sort(() => 0.5 - Math.random());
      return shuffled[0]; // Selecciona una imagen aleatoria
    };

    // Inicializar las imágenes aleatorias
    const initialImages = [
      getRandomImage(),
      getRandomImage(),
      getRandomImage(),
    ];
    setImages(initialImages);

    // Cambiar imágenes en intervalos aleatorios
    const timers = initialImages.map((_, index) => {
      const changeImage = () => {
        const newImage = getRandomImage();
        setImages((prev) => {
          const updatedImages = [...prev];
          updatedImages[index] = newImage;
          return updatedImages;
        });
      };

      // Duración aleatoria entre 1 y 3 segundos
      const randomDuration = Math.random() * 2000 + 1000; // entre 1000ms y 3000ms
      const intervalId = setInterval(changeImage, randomDuration);
      return intervalId;
    });

    // Limpiar los timers al desmontar el componente
    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, []);

  return (
    <div className="home--container">
      <div className="home--container--aside">
        {/* Renderiza el texto con HTML usando dangerouslySetInnerHTML */}
        <div
          className="banner--text rotating-text"
          dangerouslySetInnerHTML={{ __html: textoBanner }}
        />
      </div>
      <div
        className="home--container--image"
        style={{ position: "relative", height: "100vh" }}
      >
        {images.map((src, index) => {
          // Tamaño aleatorio entre 200px y 600px
          const width = Math.random() * 400 + 200; // entre 200px y 600px

          // Verifica si la imagen ha fallado antes
          const isFailed = failedImages[src];

          return (
            <img
              key={index}
              src={src}
              alt=""
              className="home-image"
              style={{
                position: "absolute",
                top: `${Math.random() * 40 + 0}vh`, // Rango de 0vh a 40vh
                left: `${Math.random() * 60 + 0}vw`, // Rango de 0vw a 40vw
                width: `${width}px`, // Tamaño aleatorio
                maxWidth: "600px", // Ancho máximo
                transition: "opacity 0.5s ease-in-out",
                opacity: isFailed ? 0 : 1, // Oculta la imagen si falló
              }}
              onError={() => {
                setFailedImages((prev) => ({ ...prev, [src]: true })); // Marca la imagen como fallida
              }}
              onLoad={() => {
                setFailedImages((prev) => ({ ...prev, [src]: false })); // Resetea el estado si se carga correctamente
              }}
            />
          );
        })}
      </div>
      {proyectos.length > 0 ? (
        <div className="proyectos-list">
          {proyectos.map((proyecto, index) => (
            <div key={index} className="proyecto-item">
              <h3>{proyecto.nombre}</h3>
            </div>
          ))}
        </div>
      ) : (
        <p>Cargando proyectos...</p>
      )}
      <Bio />
    </div>
  );
}

export default Home;
