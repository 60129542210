// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GridProyectos from "./GridProyectos";
import DetalleProyecto from "./DetalleProyecto";
import Layout from "./Layout";
import Home from "./Home"; // Importamos la página Home con la imagen centrada
import Bio from "./Bio";

function App() {
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Todos");
  const [mostrarGrid, setMostrarGrid] = useState(false); // Controlar si se muestra el grid
  const [proyectos, setProyectos] = useState([]); // Estado para proyectos

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error("Error al cargar los datos");
        }
        const data = await response.json();
        setProyectos(data.proyectos); // Asigna los proyectos a la variable de estado
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Filtrar proyectos según la categoría seleccionada
  const proyectosFiltrados = proyectos.filter((proyecto) => {
    if (categoriaSeleccionada === "Todos") {
      return true;
    } else if (categoriaSeleccionada === "New") {
      return proyecto.isNew; // Filtrar solo proyectos nuevos
    } else {
      return proyecto.categoria === categoriaSeleccionada;
    }
  });

  return (
    <Router>
      <Layout
        setCategoriaSeleccionada={(cat) => {
          setCategoriaSeleccionada(cat);
          setMostrarGrid(true); // Mostrar el grid cuando se seleccione una categoría
        }}
        categoriaSeleccionada={categoriaSeleccionada}
      >
        <Routes>
          {/* Ruta inicial */}
          <Route
            path="/"
            element={
              !mostrarGrid ? (
                <Home /> // Mostrar la página Home si no se ha seleccionado una categoría
              ) : (
                <GridProyectos proyectosFiltrados={proyectosFiltrados} />
              )
            }
          />

          {/* Agregar ruta para /home */}
          <Route
            path="/home"
            element={
              <Home /> // Mostrar la página Home al ir a /home
            }
          />

          {/* Ruta dinámica para los detalles del proyecto */}
          <Route path="/proyecto/:slug" element={<DetalleProyecto />} />
          <Route path="/bio" element={<Bio />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
