// src/DetalleProyecto.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function DetalleProyecto() {
  const { slug } = useParams();
  const [proyecto, setProyecto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Función para obtener los datos desde data.json
    const fetchData = async () => {
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error("Error al cargar los datos");
        }
        const data = await response.json();
        const foundProyecto = data.proyectos.find((p) => p.slug === slug);
        setProyecto(foundProyecto);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return <h2>Cargando proyecto...</h2>;
  }

  if (!proyecto) {
    return <h2>Proyecto no encontrado</h2>;
  }

  // Función para renderizar los detalles con enlaces inline, soporte para texto en negrita y saltos de línea
  const renderDetalles = (detalle) => {
    const regexLink = /\[\[(.*?)\]\]\((.*?)\)/g; // Regex para [[Texto]](URL)
    const boldRegex = /\[\[negrita:(.*?)\]\]/g; // Regex para [[negrita:Texto]]

    // Función para procesar el texto, buscando y reemplazando enlaces, negritas y saltos de línea
    const procesarTexto = (texto) => {
      return (
        texto
          // Procesa los enlaces
          .replace(regexLink, (match, textoEnlace, url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${textoEnlace}</a>`;
          })
          // Procesa el texto en negrita
          .replace(boldRegex, (match, textoNegrita) => {
            return `<strong>${textoNegrita}</strong>`;
          })
          // Reemplaza los saltos de línea (\n) por <br />
          .replace(/\n/g, "<br />")
      );
    };

    // Procesamos el contenido del detalle, pero sin separar en párrafos, ya que solo queremos manejar el \n
    return (
      <span
        dangerouslySetInnerHTML={{ __html: procesarTexto(detalle.contenido) }}
      />
    );
  };

  return (
    <section className="proyecto">
      <div className="proyecto--aside"></div>
      <div className="proyecto--content">
        <div className="proyecto--content--box">
          <h1>{proyecto.nombre}</h1>
          <h2 className="proyecto--content--subtitle">{proyecto.subtitle}</h2>
          {proyecto.cover.path && (
            <img
              src={proyecto.cover.path}
              className="proyecto--cover"
              alt={proyecto.cover.alt}
            />
          )}

          <div className="proyecto--parrafos">
            {proyecto.detalles.map((detalle, index) => {
              if (detalle.tipo === "h3") {
                return <h3 key={index}>{detalle.contenido}</h3>;
              } else if (detalle.tipo === "parrafo") {
                return (
                  <p
                    key={index}
                    style={{ marginBottom: "20px", lineHeight: "1.6" }}
                  >
                    {renderDetalles(detalle)}
                  </p>
                );
              }
              return null;
            })}

            {proyecto.links && proyecto.links.length > 0 && (
              <>
                <h2 className="proyecto--parrafos--prensa">Prensa:</h2>
                <ul>
                  {proyecto.links.map((link, index) => (
                    <li key={index}>
                      &#8594;
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <div>
            {proyecto.imagenes.map((imagen, index) => (
              <img
                key={index}
                className="proyecto--img"
                style={{ marginBottom: "20px", lineHeight: "1.6" }}
                src={imagen.path}
                alt={imagen.alt}
              />
            ))}
          </div>
        </div>
        <div></div>
      </div>
    </section>
  );
}

export default DetalleProyecto;
