// src/Bio.js
import React from "react";

const Bio = () => {
  return (
    <section className="proyecto">
      <div className="proyecto--aside"></div>
      <div className="proyecto--content">
        <div className="proyecto--content--box">
          <h1 style={{ marginBottom: "100px" }}>Anto Rodríguez</h1>

          <img src="img/bio/img-4c5bea389218-1.jpeg" style={{ width: "65%" }} />

          <div className="proyecto--parrafos">
            <br></br>
            <p>Mieres, Asturias, 1986. </p>
            <br></br>
            <p>Maricón, investigaor, artista-investigador.</p>
            <br></br>
            <p>
              Actualmente trabajo como investigador posdoctoral (Margarita Salas
              UCLM-UCM) en el grupo{" "}
              <a
                href="https://www.ucm.es/arteinvestigacion"
                target="_blank"
                style={{ wordBreak: "normal" }}
              >
                Investigación, arte, universidad: documentos para un debate
              </a>{" "}
              en la Facultad de Bellas Artes de la UCM. Formo parte de la
              Oficina de creación e investigación{" "}
              <a
                href="https://www.dorothymichaels.es"
                target="_blank"
                style={{ wordBreak: "normal" }}
              >
                Dorothy Michaels
              </a>{" "}
              de Madrid.
            </p>
            <br></br>
            <p>
              Entre mis últimas producciones más queridas están el libro ¡Eres
              tan travesti! Breve historia del transformismo en España (Egales,
              2024); el podcast-documental Color Julay, en el que hablo de la
              historia de los cancioneros y del transformismo en España; y la
              serie audiovisual Carrizo (producida por La Casa Encendida, 2019).
              Puedes ver el resto de cosas en los apartados de la web.
            </p>
            <br></br>
            <p>
              Últimamente, lo que más disfruto es hacer conferencias
              performativas en las que cuento cosas, enseño mis colecciones de
              fotos antiguas o revistas porno de los 70 y canto coplas. También
              subo bastantes movidas a instagram: @anto_rv
            </p>
            <br></br>
            <p>
              Me formé en la práctica y teoría de las artes escénicas
              contemporáneas en la ESAD de Asturias, UNED, URJC, UAH y UCLM.
              Entre mis trabajos destacaría la película-fantasma Frankenstein
              (mejor largometraje asturiano de la 51ª edición del Festival
              Internacional de Cine de Gijón), el concierto-karaoke Vivir en
              videoclip (producción del festival Los Veranos de la Villa), la
              pieza escénica La Traviata (estrenada en Teatro Pradillo y el
              Teatro Español de Madrid) o la conferencia escénica Lo otro: el
              concierto.
            </p>
            <br></br>
            <p>
              En octubre de 2019 defendí la tesis doctoral que había
              desarrollado como FPI dentro del proyecto de investigación
              Teatralidades Disidentes de la UCLM (financiado por el MINECO) y
              de la asociación de investigadores y creadores en artes escénicas
              Artea de la que soy secretario. Obtuve la calificación de
              sobresaliente Cum Laude. Mi investigación giraba en torno a la
              escritura escénica autobiográfica expandida en diversos formatos y
              la generación de dispositivos de escritura artística y teórica en
              los que resignificar o cuestionar las relaciones a través de
              herramientas de la cultura pop.{" "}
            </p>
            <br></br>
            <p>
              Entre mis últimos trabajos escénicos se encuentran las piezas
              Arriba, Al Altu la lleva, La nada más absoluta, Notas para una
              conferencia troll, Mamá Patria, La salita, Los libros, .jpg,
              Vinilismos o Mapa Sentimental Sonoro.{" "}
            </p>
            <br></br>
            <p>
              Como docente he impartido clases en diversos contextos como, por
              ejemplo, el Máster en Práctica Escénica y Cultura Visual de la
              UCLM y Artea en Madrid, las Facultades de Bellas Artes de la UCM y
              la UCLM, la MITAV de la Universidad Nacional de Colombia o el
              CA2M.
            </p>
            <br></br>
            <p>
              Mi trabajo ha sido acogido en contextos e instituciones como:
              MNCARS, Facultad de Bellas Artes de Cuenca, Facultad de Bellas
              Artes de la UCM, Teatro Pradillo, Festival Qué Puede un Cuerpo,
              Teatros del Canal, Seminario Internacional La Situación, Teatro
              Español, La Poderosa, CND, Muestra Estatal de Teatro de Jalisco,
              MITAV de la Universidad Nacional de Colombia, Festival Los Veranos
              de la Villa, Conde Duque Madrid, etc.
            </p>
            <br></br>
            <p>
              También colaboro o he colaborado en las creaciones de otros
              artistas como Juan Dominguez, Amalia Fernández, María Jerez, Óscar
              Bueno, Cris Blanco o Cuqui Jerez.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bio;
