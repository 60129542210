import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

function GridProyectos({ proyectosFiltrados }) {
  const gridRef = useRef(null); // Referencia para el grid

  // Animar las cards de los proyectos cuando cambie el filtro
  useEffect(() => {
    const cards = gridRef.current.querySelectorAll(".grid--proyecto--card"); // Selecciona todas las cards
    gsap.fromTo(
      cards,
      { opacity: 0, y: 50 }, // Estado inicial: invisibles y desplazadas hacia abajo
      { opacity: 1, y: 0, duration: 0.5, stagger: 0.1, ease: "power2.out" } // Estado final: visibles con animación escalonada
    );
  }, [proyectosFiltrados]); // Se ejecuta cada vez que los proyectos filtrados cambian

  return (
    <div className="wrapper">
      <div className="grid--proyectos" ref={gridRef}>
        {proyectosFiltrados.map((proyecto) => (
          <div key={proyecto.id} className="grid--proyecto--card">
            <div className="grid--proyecto--card--icon">
              <img src={proyecto.icono} alt={proyecto.nombre}></img>
            </div>
            <Link
              to={`/proyecto/${proyecto.slug}`}
              className="link--to--project"
            >
              <h3 className="grid--proyecto--card--title">{proyecto.nombre}</h3>
            </Link>
            <p className="grid--proyecto--card--year">{proyecto.year}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GridProyectos;
