// src/Layout.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { gsap } from "gsap"; // Importar GSAP

function Layout({ setCategoriaSeleccionada, children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filtroActivo, setFiltroActivo] = useState(false);
  const [rutaAnterior, setRutaAnterior] = useState(null);
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const popupRef = useRef(null); // Referencia para el pop-up

  const categorias = [
    { nombre: "New", icono: "/img/icons/starspng.png" },
    { nombre: "Escena", icono: "/img/icons/stage.png" },
    { nombre: "Publicaciones", icono: "/img/icons/book.png" },
    { nombre: "Comisariados", icono: "/img/icons/arrows.png" },
    { nombre: "Otros", icono: "/img/icons/other.png" },
    { nombre: "Todos", icono: "/img/icons/all.png" },
  ];

  const handleFiltroClick = (categoria) => {
    if (!filtroActivo) {
      setRutaAnterior(location.pathname);
    }

    setCategoriaSeleccionada(categoria);
    setFiltroActivo(true);
    navigate("/");
  };

  const handleCerrarFiltro = () => {
    setFiltroActivo(false);
    if (rutaAnterior) {
      navigate(rutaAnterior);
      setRutaAnterior(null);
    } else {
      navigate("/Home");
    }
  };

  const togglePopup = () => {
    setMostrarPopup(!mostrarPopup);
  };

  useEffect(() => {
    if (mostrarPopup) {
      gsap.fromTo(
        popupRef.current,
        { opacity: 0, top: 100 },
        { opacity: 1, top: 0, duration: 0.5, ease: "power2.out" }
      );
    } else if (popupRef.current) {
      gsap.to(popupRef.current, {
        opacity: 0,
        scale: 0.5,
        duration: 0.5,
        ease: "power2.in",
      });
    }
  }, [mostrarPopup]);

  useEffect(() => {
    if (location.pathname !== "/") {
      setFiltroActivo(false);
    }
  }, [location.pathname]);

  return (
    <div>
      <header>
        <div className="header--contact">
          <Link
            to="/bio"
            className="button--filter"
            id="bio"
            style={{ backgroundColor: "black" }}
          >
            <img src="/img/icons/human.png" style={{ width: "25px" }}></img>
          </Link>
        </div>
        <Link to="/Home">
          <h1 className="header--name">Anto Rodríguez</h1>
        </Link>
        <h2 className="header--year">1986</h2>
      </header>

      <nav style={{ display: "flex", justifyContent: "start" }}>
        <div className="nav--open-menu">
          {filtroActivo && (
            <button
              onClick={handleCerrarFiltro}
              className="cerrar--filtro"
              style={{ fontSize: "1.3rem" }}
            >
              X
            </button>
          )}
        </div>
        {categorias.map((categoria) => (
          <button
            key={categoria.nombre}
            onClick={() => handleFiltroClick(categoria.nombre)}
            className="button--filter"
          >
            <img
              src={categoria.icono}
              alt={categoria.nombre}
              style={{ width: "25px", height: "25px" }}
            />
            <p className="button-text">
              {categoria.nombre === "Comisariados"
                ? `${categoria.nombre} y exposiciones`
                : categoria.nombre}
            </p>
          </button>
        ))}
        <button
          className="button--filter instagram"
          style={{ marginLeft: "auto" }}
        >
          <a href="https://www.instagram.com/anto_rv/" target="_blank">
            <img src="/img/icons/instagram.png"></img>
          </a>
        </button>
        <button
          className="button--filter email"
          style={{ justifySelf: "flex-end", width: "70px" }}
        >
          <a href="mailto:antorodriguezvelasco@gmail.com">
            {" "}
            <img src="/img/icons/mail.png"></img>
          </a>
        </button>
        {/* Cambiamos el botón Bio para usar Link */}

        {/* <div className="social--link">
          <button className="button--filter instagram">
            <a href="https://www.instagram.com/anto_rv/" target="_blank">
              <img src="/img/icons/instagram.png"></img>
            </a>
          </button>
          <button
            className="button--filter"
            style={{ justifySelf: "flex-end", width: "70px" }}
          >
            <a href="mailto:antorodriguezvelasco@gmail.com">
              {" "}
              <img src="/img/icons/mail.png"></img>
            </a>
          </button>
        </div> */}
      </nav>

      <main>{children}</main>

      {/* Pop-up de contacto */}
      {mostrarPopup && (
        <div className="popup-overlay">
          <button className="close-popup" onClick={togglePopup}>
            X
          </button>
          <div ref={popupRef} className="popup">
            <div className="popup--social">
              <ul>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="green"
                    viewBox="0 0 128 128"
                  >
                    <path
                      fill="green"
                      d="m18.964 39.486 3.114 2.537L6.355 61.317 3.242 58.78zM108.846 39.248l15.915 19.53-3.114 2.538-15.915-19.53z"
                    />
                    <path
                      fill="green"
                      d="M124.772 127.782H3.228V58.583h4.016v65.183h113.512V58.583h4.016z"
                    />
                    <path
                      fill="green"
                      d="m39.474 93.194 2.697 2.974-35.106 31.831-2.697-2.974zM88.522 93.197l35.11 31.826-2.697 2.975-35.11-31.826z"
                    />
                    <path
                      fill="green"
                      d="M87.802 95.657H40.199L19.11 74.101V0h89.782v74.101l-21.09 21.556zm-45.913-4.015h44.225l18.764-19.179V4.017H23.126v68.446l18.763 19.179z"
                    />
                    <path
                      fill="green"
                      d="M64.548 72.013c-13.063 0-23.688-10.549-23.688-23.516 0-12.964 10.626-23.512 23.688-23.512 7.128 0 13.807 3.139 18.324 8.612l-3.097 2.558c-3.753-4.547-9.303-7.153-15.228-7.153-10.848 0-19.673 8.745-19.673 19.495 0 10.752 8.825 19.5 19.673 19.5 4.328 0 8.431-1.364 11.864-3.944l2.412 3.212c-4.134 3.105-9.07 4.748-14.275 4.748z"
                    />
                    <path
                      fill="green"
                      d="M60.633 60.049c-.001 0 0 0 0 0-.929 0-1.912-.146-2.922-.434-3.041-.869-5.34-3.394-6.148-6.754-1.09-4.524.594-9.536 4.392-13.079 3.325-3.098 7.857-3.748 11.076-3.748 2.697 0 4.714.449 4.798.469l2.114.478-.636 2.071c-.161.52-3.928 12.771-4.598 14.684-1.388 3.952-4.407 6.313-8.076 6.313zm6.397-19.998c-2.497 0-5.97.463-8.338 2.669-3.275 3.056-3.814 6.757-3.226 9.201.473 1.963 1.692 3.36 3.347 3.832.65.186 1.263.28 1.819.28 2.49 0 3.705-1.973 4.285-3.628.477-1.356 2.703-8.549 3.855-12.282a21.628 21.628 0 0 0-1.742-.072z"
                    />
                    <path
                      fill="green"
                      d="M73.686 59.837c-2.044 0-3.935-.698-5.44-2.057-3.11-2.806-.969-12.295.584-17.701l3.859 1.108c-1.72 5.996-2.536 12.504-1.713 13.653 1.489 1.338 3.39 1.078 4.712.617 3.032-1.055 6.506-4.528 7.283-9.13.561-3.326-.098-8.342-6.423-13.344l2.49-3.15c8.282 6.55 8.5 13.564 7.893 17.163-.93 5.506-5.01 10.545-9.923 12.255a10.099 10.099 0 0 1-3.322.586z"
                    />
                    <g>
                      <path
                        fill="green"
                        d="M86.428 95.657H41.573L4.743 60.165l2.786-2.892 35.664 34.369h41.615l35.664-34.369 2.787 2.892z"
                      />
                    </g>
                  </svg>
                </li>
                <li>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="red"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="red"
                      fillRule="evenodd"
                      d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill="blue"
                    viewBox="0 0 80 80"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Layout;
